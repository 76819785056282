<template>
  <div class="text-h6 text-weight-bold text-left q-mt-sm q-ml-lg q-ml-lg">
    {{ $t("General.summary") }}
    {{ settings.system_name }}
  </div>
  <q-card class="q-ma-md q-mr-md q-ml-md">
    <q-card-section class="q-pt-md text-left q-pb-none">
      {{ $t("General.balance") }}
      <p class="text-weight-bold text-h4 q-ma-xs">
        {{ points.points.points }}
        {{ settings.currency_abbreviation }}
      </p>
    </q-card-section>
    <q-item class="justify-between">
      <q-card-section class="q-pa-sm text-weight-medium">
        {{ $t("Home.last-movements") }}
      </q-card-section>
      <router-link to="/transacciones">
        <q-card-section
          class="underline-text title-transacciones q-pa-sm text-weight-bold"
        >
          {{ $t("Home.see-all") }}
        </q-card-section>
      </router-link>
    </q-item>

    <q-list
      v-for="transaction in transactions.points_records.slice(-5).reverse()"
      :key="transaction.id"
    >
      <q-item clickable v-ripple @click="showTransactionInfo()">
        <q-item-section avatar style="min-width: 20px">
          <q-icon
            :class="
              transaction.action === 'addition' ? 'addition' : 'subtraction'
            "
            :name="
              transaction.action === 'addition' ? 'arrow_forward' : 'arrow_back'
            "
            size="20px"
          />
        </q-item-section>
        <q-item-section
          class="text-left text-weight-medium text-info-transaction"
          >{{ transaction.type }}
          <div class="text-left text-grey text-caption">
            {{ transaction.created_at }}
          </div>
        </q-item-section>
        <q-item-section
          class="text-weight-medium text-right"
          style="flex-direction: row; justify-content: flex-end"
        >
          <span v-if="transaction.action === 'addition'">+</span
          ><span v-else>-</span>
          {{ transaction.points }}
          {{ settings.currency_abbreviation }}</q-item-section
        >
      </q-item>
    </q-list>
  </q-card>

  <!-- Dialog Past Transaction show -->
  <PastTransactionsShow
    v-model="dialog"
    :transaction="transactions.points_records.id"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";
import { defineAsyncComponent } from "vue";
import settings from "@/mixins/settings";
import points from "@/mixins/points";

export default {
  mixins: [settings, points],

  components: {
    PastTransactionsShow: defineAsyncComponent(() =>
      import("../../module-3-transactions/components/PastTransactionsShow.vue")
    ),
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    transactions() {
      return this.$store.state.transactions.transactions;
    },
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions("transactions", ["getTransactions"]),

    // NOFUNCIONA // Abre el modal pasando el ID
    // showTransactionDesktop() {
    //   // this.$router.push({name: "Transacciones"});
    //   // this.dialog = true;
    //   // this.transactions.points_records.id = id;
    // },

    showTransactionInfo() {
      this.$router.push({ name: "Transacciones" });
    },
  },
  created() {
    this.getTransactions(this.user.id);
  },
};
</script>

<style lang="scss" scoped>
.q-card {
  box-shadow: 0px 4px 8px 0px #00000026;
  border-radius: 25px;
  max-width: 90%;
  margin-bottom: 100px;
}

.title-transacciones {
  color: v-bind("settings.color_accent");
}

.text-info-transaction {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  min-width: 145px;
}

.addition {
  color: v-bind("settings.color_accent");
}

.subtraction {
  color: v-bind("settings.color_primary");
}
</style>
